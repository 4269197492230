.logo {
  margin: 0;
  padding: 0;
  color: white;
}

.invoice__info {
  display: flex;
  flex-direction: column;
}

.invoice__info-section {
  display: flex;
  justify-content: space-between;
  gap: 50px;
}

.invoice__info-subsection {
  flex: 1;
}

.invoice__info-fromto {
  overflow: hidden;
  resize: vertical;
}

.invoice__logo-preview {
  position: relative;
}

.invoice__logo-preview-img {
  max-width: 150px;
  max-height: 150px;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.invoice__logo-error {
  color: red;
  opacity: 0.7;
}

.remove__button {
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  background-color: var(--form-element-background-color);
  border: var(--border-width) solid var(--border-color);
  border-radius: var(--border-radius);
  outline: 0;
  /* opacity: .5; */
}

.date-picker {
  visibility: hidden;
  transition: visibility 0.2s linear 0.2s, opacity 0.2s linear;
}

.date-picker_opened {
  display: flex;
  visibility: visible;
  opacity: 1;
  transition-delay: 0.2s;
}

.invoice__items {
  display: grid;
  gap: 5px;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
}

.invoice__item-name {
  height: calc(
    1rem * var(--line-height) + var(--form-element-spacing-vertical) * 2 +
      var(--border-width) * 2
  );
  /* resize: none; */
  /* overflow: 'auto'; */
  overflow: hidden;
  resize: vertical;
}

.invoice__item-remove {
  color: var(--card-background-color);
}

.invoice__item-button {
  height: calc(
    1rem * var(--line-height) + var(--form-element-spacing-vertical) * 2 +
      var(--border-width) * 2
  );
  margin-top: calc(var(--spacing) * 0.25);
}

.price {
  display: flex;
  justify-content: space-between;
  gap: 50px;
}

.price > div {
  flex: 1;
}

.add__label {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
}

.add__label-label {
  width: 100%;
}

.about__inv-img {
  max-width: 400px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.about__section {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

@media screen and (max-width: 540px) {
  .invoice__info-section {
    flex-direction: column;
  }
}

@media screen and (max-width: 540px) {
  .invoice__items {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 540px) {
  .price {
    flex-direction: column-reverse;
  }
}

@media screen and (max-width: 540px) {
  .about__section {
    flex-direction: column;
  }
}
